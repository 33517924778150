<template>
  <section class="wrapper font-montserrat">
    <div class="container-fostrap">
      <div class="container py-4">
        <h4 class="text-left text-4xl font-black">Current Raising Funds</h4>
      </div>
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-4">
              <router-link to="/crowdfund-details" class="card text-black">
                <div class="img-card">
                  <img src="https://via.placeholder.com/500x600" />
                </div>
                <div class="card-content">
                  <div class="bg-oonpaysecondary font-semibold p-3">
                    #MayJourney21
                  </div>
                  <div class="p-3 space-y-5">
                    <h4 class="text-green-500">Lagos Nigeria</h4>
                    <h5 class="font-semibold">Support Maya Recovery Today</h5>
                    <p class="">
                      The Family of Maya is greater for to everyone...
                    </p>
                    <p>Funding Goal Reached</p>
                    <h5>
                      <span class="font-medium"> N500,000 raised of</span>
                      N1,0000,0000
                    </h5>

                    <div class="progress">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        style="width: 30%"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        30%
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xs-12 col-sm-4">
              <router-link to="/crowdfund-details" class="card text-black">
                <div class="img-card">
                  <img src="https://via.placeholder.com/500x600" />
                </div>
                <div class="card-content">
                  <div class="bg-oonpaysecondary font-semibold p-3">
                    #MayJourney21
                  </div>
                  <div class="p-3 space-y-5">
                    <h4 class="text-green-500">Lagos Nigeria</h4>
                    <h5 class="font-semibold">Support Maya Recovery Today</h5>
                    <p class="">
                      The Family of Maya is greater for to everyone...
                    </p>
                    <p>Funding Goal Reached</p>
                    <h5>
                      <span class="font-medium"> N500,000 raised of</span>
                      N1,0000,0000
                    </h5>

                    <div class="progress">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        style="width: 30%"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        30%
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xs-12 col-sm-4">
              <router-link to="/crowdfund-details" class="card text-black">
                <div class="img-card">
                  <img src="https://via.placeholder.com/500x600" />
                </div>
                <div class="card-content">
                  <div class="bg-oonpaysecondary font-semibold p-3">
                    #MayJourney21
                  </div>
                  <div class="p-3 space-y-5">
                    <h4 class="text-green-500">Lagos Nigeria</h4>
                    <h5 class="font-semibold">Support Maya Recovery Today</h5>
                    <p class="">
                      The Family of Maya is greater for to everyone...
                    </p>
                    <p>Funding Goal Reached</p>
                    <h5>
                      <span class="font-medium"> N500,000 raised of</span>
                      N1,0000,0000
                    </h5>

                    <div class="progress">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        style="width: 30%"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        30%
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style  scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,900);
.wrapper {
  display: table;
  height: 100%;
  width: 100%;
}

.container-fostrap {
  display: table-cell;
  padding: 1em;
  text-align: center;
  vertical-align: middle;
}

a:hover{
 text-decoration: none;
 color: #0c4369;
}

.card {
  display: block;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.25s;
}
.card:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img-card {
  width: 100%;
  height: 200px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: block;
  overflow: hidden;
}
.img-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 0.25s ease;
}
.card-content {
  text-align: left;
}
</style>