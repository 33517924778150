<template>
  <div class="container my-10 py-32">
    <img src="../../../assets/images/Crowdfund/Crowdfund .png" alt="">
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>