<template>
  <div class="container-fluid font-montserrat py-20">
    <div class="container">
      <h3 class="text-center text-black text-4xl">
        Oonpay Crowfunding Hub Is The Easiest Online fundraising Mobile friendly
        Platform
      </h3>
      <p class="text-center text-2xl font-semibold">Get started in minutes and accept online donations instantly</p>
      <section class="text-gray-600 body-font">
        <div class="py-24 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/3 lg:mb-0 mb-6 px-3">
              <div class="h-full text-center">
                <img
                  alt="testimonial"
                  class="
                    w-20
                    h-20
                    mb-8
                    object-cover object-center
                    inline-block
                    border-2 border-gray-200
                    bg-gray-100
                  "
                  src="https://dummyimage.com/302x302"
                />
                <h4 class="text-2xl font-semibold">Secure Payment Processing</h4>
                <p class="leading-relaxed">
                  Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac
                  odio mauris justo. Luctus arcu, urna praesent at id quisque
                  ac. Arcu es massa vestibulum malesuada, integer vivamus elit
                  eu mauris eus, cum eros quis
                </p>
              </div>
            </div>
            <div class="lg:w-1/3 lg:mb-0 mb-6 px-3">
              <div class="h-full text-center">
                <img
                  alt="testimonial"
                  class="
                    w-20
                    h-20
                    mb-8
                    object-cover object-center
                    inline-block
                    border-2 border-gray-200
                    bg-gray-100
                  "
                  src="https://dummyimage.com/300x300"
                />
                <h4 class="text-2xl font-semibold">Reach A Global Audience</h4>
                <p class="leading-relaxed">
                  Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac
                  odio mauris justo. Luctus arcu, urna praesent at id quisque
                  ac. Arcu es massa vestibulum malesuada, integer vivamus elit
                  eu mauris eus, cum eros quis
                </p>
              </div>
            </div>
            <div class="lg:w-1/3 lg:mb-0 px-3">
              <div class="h-full text-center">
                <img
                  alt="testimonial"
                  class="
                    w-20
                    h-20
                    mb-8
                    object-cover object-center
                    inline-block
                    border-2 border-gray-200
                    bg-gray-100
                  "
                  src="https://dummyimage.com/305x305"
                />
                <h5 class="text-2xl font-semibold">Print And Share Your Campaign Flyers</h5>
                <p class="leading-relaxed">
                  Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac
                  odio mauris justo. Luctus arcu, urna praesent at id quisque
                  ac. Arcu es massa vestibulum malesuada, integer vivamus elit
                  eu mauris eus, cum eros quis
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="text-gray-600 body-font">
        <div class="mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/3 lg:mb-0 mb-6 px-4">
              <div class="h-full text-center">
                <img
                  alt="testimonial"
                  class="
                    w-20
                    h-20
                    mb-8
                    object-cover object-center
                    inline-block
                    border-2 border-gray-200
                    bg-gray-100
                  "
                  src="https://dummyimage.com/302x302"
                />
                <h4 class="text-2xl font-semibold">Secure Payment Processing</h4>
                <p class="leading-relaxed">
                  Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac
                  odio mauris justo. Luctus arcu, urna praesent at id quisque
                  ac. Arcu es massa vestibulum malesuada, integer vivamus elit
                  eu mauris eus, cum eros quis
                </p>
              </div>
            </div>
            <div class="lg:w-1/3 lg:mb-0 mb-6 px-4">
              <div class="h-full text-center">
                <img
                  alt="testimonial"
                  class="
                    w-20
                    h-20
                    mb-8
                    object-cover object-center
                    inline-block
                    border-2 border-gray-200
                    bg-gray-100
                  "
                  src="https://dummyimage.com/300x300"
                />
                <h4 class="text-2xl font-semibold">Reach A Global Audience</h4>
                <p class="leading-relaxed">
                  Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac
                  odio mauris justo. Luctus arcu, urna praesent at id quisque
                  ac. Arcu es massa vestibulum malesuada, integer vivamus elit
                  eu mauris eus, cum eros quis
                </p>
              </div>
            </div>
            <div class="lg:w-1/3 lg:mb-0 px-4">
              <div class="h-full text-center">
                <img
                  alt="testimonial"
                  class="
                    w-20
                    h-20
                    mb-8
                    object-cover object-center
                    inline-block
                    border-2 border-gray-200
                    bg-gray-100
                  "
                  src="https://dummyimage.com/305x305"
                />
                <h5 class="text-2xl font-semibold">Print And Share Your Campaign Flyers</h5>
                <p class="leading-relaxed">
                  Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac
                  odio mauris justo. Luctus arcu, urna praesent at id quisque
                  ac. Arcu es massa vestibulum malesuada, integer vivamus elit
                  eu mauris eus, cum eros quis
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.container-fluid {
  background: #f7f7f7;
}
</style>