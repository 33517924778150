<template>
  <div class="container-fluid">
    <div class="container py-20 lg:py-0">
    <div class="row py-14  space-y-10 lg:space-y-0">
      <div class="col-md-3 ">
        <img src="../../assets/icons/googleplay.png" alt="" />
      </div>
      <div class="col-md-3">
        <img src="../../assets/icons/applestore.png" alt=""  />
      </div>
      <div class="col-md-6">
        <vue-tel-input v-model="phone"></vue-tel-input>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
    };
  },
};
</script>

<style  scoped>
.container-fluid{
  background: #0c4369;
}
</style>