<template>
  <div class="container font-montserrat">
    <div class="row py-14">
      <div class="col-md-6">
        <h4 class="capitalize font-black py-2 text-3xl text-center">
          Raise Funds for Causes and People That Matter To You And The World
        </h4>
        <p class="text-gray-500 text-center text-2xl pb-4">Oonpay is fast and secure, and has no startup fees</p>
        <div class="flex justify-center">
          <ul class="text-left text-lg  list-decimal font-extrabold">
          <li>CROWDFUND/DONATIONS</li>
          <li>Medical Bills</li>
          <li>Community Ventures</li>
          <li>Social Intiatives</li>
        </ul>
        </div>
        <p class="text-gray-500 text-lg">Create your campaign in minutes and reach a global audience</p>
        <p class="text-gray-500 text-lg">
          Create a fundraise campaign and tag it with oonpay easy to find
          #paytag and QR enabled URL link
        </p>
        <p class="text-gray-500 text-lg">
          Engage your donors with our mobile friendly search and find fund raise
          page
        </p>
        <p class="text-gray-500 text-lg">
          Extend your real-time gratitude to your campaign Donors in your
          activity wall
        </p>
        <p class="text-gray-500 text-lg">
          Print and Distribute verified and Secure campaign flyers with your
          embedded oonpay #paytag and QR Code
        </p>
        <div class="flex justify-center">
            <a
            href="/select-country"
          class="
            my-4
            rounded-full
            flex
            items-center
            shadow
            bg-oonpaysecondary
            px-4
            lg:py-3
            py-2
            text-white
            hover:bg-oonpayprimary
          "
        >
          Start Your Compaign Here
        </a>
        </div>
      </div>
      <div class="col-md-6 ">
        <img src="../../../assets/images/Crowdfund/IMAGE 1 .jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>