<template>
  <div
    class="py-32 h-80 bg-img flex flex-col justify-center items-center"
    
  >
    <div class="logo">
      <img src="../../../assets/icons/Oonpay logo.png" alt="">
    </div>
    <h2 class="text-6xl text-oonpayprimary font-bold"><span class="text-oonpaysecondary">Crowd</span>Fund</h2>
    <div class="input-wrapper">
      <div class="fa fa-search"></div>
      <input type="text" placeholder="Search For #paytag, #fundtag, Invoice" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.logo,
p {
  font-size: 1.5rem;
}

.bg-img{
    /* background: url('../../../assets/images/Crowdfund/crowdfund_banner .png'); */
    background-size: cover;
    background-position: center right;
    height: 500px;
   
}

.input-wrapper {
  display: flex;
  width: 1000px;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  border: 2px soild #dddd;
  font-size: 1.5rem;
  margin: 15px 15px;
  -webkit-box-shadow: 2px 3px 8px #dddd;
  -moz-box-shadow: 2px 3px 8px #dddd;
  box-shadow: 2px 3px 8px #dddd;
}

input {
  margin: 0 0.5rem 0 0.5rem;
  width: 100%;
  border: none;
  outline: none;
  color: #000;
}

::placeholder {
  color: #dddddd;
}

.fa-search {
  color: black;
}

@media (max-width: 640px) {
  .input-wrapper {
    display: flex;
    width: 330px;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: white;
    border: 2px soild #dddd;
    font-size: 1rem;
    margin: 15px 15px;
    -webkit-box-shadow: 2px 3px 8px #dddd;
    -moz-box-shadow: 2px 3px 8px #dddd;
    box-shadow: 2px 3px 8px #dddd;
  }
}
</style>