<template>
  <div class="bg-white">
    <the-app-bar/>
    <crowdfunding-hero />
    <crowdfundhub />
    <fund-raise />
    <campaign-cards />
    <crowd-fund-feature />
    <social-media-tags />
    <confirm-token />
  </div>
</template>

<script>
import ConfirmToken from "../../components/MainView/ConfirmToken.vue";
import CampaignCards from "../../components/MainView/CrowdFundHub/CampaignCards.vue";
import CrowdFundFeature from "../../components/MainView/CrowdFundHub/CrowdFundFeature.vue";
import Crowdfundhub from "../../components/MainView/CrowdFundHub/Crowdfundhub.vue";
import CrowdfundingHero from "../../components/MainView/CrowdFundHub/CrowdfundingHero.vue";
import FundRaise from "../../components/MainView/CrowdFundHub/FundRaise.vue";
import SocialMediaTags from "../../components/MainView/CrowdFundHub/SocialMediaTags.vue";
import TheAppBar from '../../components/NavBar/TheAppBar.vue';

export default {
  components: {
    Crowdfundhub,
    FundRaise,
    CampaignCards,
    CrowdFundFeature,
    SocialMediaTags,
    ConfirmToken,
    CrowdfundingHero,
    TheAppBar,
  },
};
</script>

<style lang="scss" scoped>
</style>