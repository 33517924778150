<template>
  <div class="container-fluid font-montserrat">
    <div class="row flex text-center flex-col items-center">
      <div class="col-md-12 py-20">
        <h2 class="text-4xl font-black text-white">Lets Start Your Raise Here</h2>
        video here
      </div>

      <h4 class="text-center font-black text-white">How it Works</h4>

      <section class="text-gray-600 body-font">
        <div class="container px-5 lg:py-24 py-16 mx-auto">
          <div class="flex flex-wrap -mx-4 -mb-10 text-center">
            <div class="sm:w-1/2 mb-10 lg:px-4">
              <div class="rounded-lg h-64 overflow-hidden">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src="https://dummyimage.com/1201x501"
                />
              </div>
              <h2
                class="title-font text-2xl font-medium text-white mt-6 mb-3"
              >
                Raise Fund For Individuals
              </h2>
            </div>
            <div class="sm:w-1/2 mb-10 lg:px-4">
              <div class="rounded-lg h-64 overflow-hidden">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src="https://dummyimage.com/1202x502"
                />
              </div>
              <h2
                class="title-font text-2xl font-medium text-white mt-6 mb-3"
              >
                Raise Fund For Cause/Charity
              </h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.container-fluid {
  background: #0c4369;
}
</style>